import { Button, Space } from "antd";
import { Workspace } from "polotno/canvas/workspace";
import { createStore } from "polotno/model/store";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { useEffect } from "react";
import { Toolbar } from "polotno/toolbar/toolbar";
import { PagesTimeline } from "polotno/pages-timeline";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";

import "@blueprintjs/core/lib/css/blueprint.css";
import { DownloadButton } from "polotno/toolbar/download-button";

const ImageContentEditorPage = () => {
  const store = createStore();
  const page = store.addPage();

  useEffect(() => {
    page.addElement({
      x: 50,
      y: 50,
      type: "text",
      fill: "black",
      text: "hello",
    });
  }, []);

  const ActionControls = ({ store }) => {
    return (
      <div>
        <DownloadButton store={store} />
        <Button
          onClick={() => {
            const json = JSON.stringify(store.toJSON());
            const blob = new Blob([json], { type: 'application/json' })
            const href = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = "banner.json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          Export
        </Button>
      </div>
    );
  };

  return (
    <div className="flex flex-row w-full h-full">
      <PolotnoContainer>
        <SidePanelWrap>
          <SidePanel store={store} />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar
            store={store}
            downloadButtonEnabled
            components={{ ActionControls }}
          />
          <Workspace store={store} components={{ PageControls: () => null }} />
          <ZoomButtons store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </div>
  );
};

export default ImageContentEditorPage;
