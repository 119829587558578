import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../layouts/AppLayout/AppLayout";
import UnonymLayout from "../layouts/UnonymLayout/UnonymLayout";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ForgotPassworPage from "../pages/ForgotPassworPage/ForgotPassworPage";
import HomePage from "../pages/HomePage/HomePage";
import LandingPage from "../pages/LandingPage/LandingPage";
import LoginPage from "../pages/LoginPage/LoginPage";
import RegisterPage from "../pages/RegisterPage/RegisterPage";
import SetNewPassworPage from "../pages/SetNewPassword/SetNewPassword";
import LoopsPage from "../pages/Loops/LoopsPage";
import BrandHubPage from "../pages/BrandHub/BrandHubPage";
import ContentInspirationsPage from "../pages/ContentInspirations/ContentInspirationsPage";
import BrandHubCreateNewPage from "../pages/BrandHub/CreateNew/BrandHubCreateNewPage";
import LoopDetails from "../pages/LoopDetails/LoopDetails";
import RevisionEditor from "../pages/RevisionEditor/RevisionEditor";
import PlanPage from "../pages/PlanPage/PlanPage";
import AccountSettingsPage from "../pages/AccountSettings/AccountSettingsPage";
import OnboardingContent from "../pages/Onboarding/OnboardingContent";
import ContentCalendar from "../pages/ContentCalendar/ContentCalendar";
import MarketingPlanner from "../pages/MarketingPlanner/MarketingPlanner";
import AccountFrozenPage from "../pages/ErrorPage/AccountFrozenPage";
import BrandHubCreateNewPageWithTab from "../pages/BrandHub/CreateNew/BrandHubCreateNewPageWithTab";
import SelectContentHome from "../pages/HomePage/SelectContentHome";
import MarketingPlannerDetail from "../pages/MarketingPlanner/MarketingPlannerDetails/MarketingPlannerDetail";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Notifications from "../pages/ContentCalendar/ContentCalendar";
import AllNotifications from "../pages/Notifications/AllNotificationsPage";
import TextToSpeechPage from "../pages/TextToSpeech/TextToSpeechPage";
import SelectedContentHome from "../pages/NewestHomePage/SelectedContentHome";
import BackgroundRemoverPage from "../pages/BrandHub/BackgroundRemover/BackgroundRemoverPage";
import RedirectToDesktop from "../pages/RedirectToDesktopPage/RedirectToDesktopPage";
import ImageContentEditorPage from "../pages/ImageContentEditor/ImageContentEditorPage";

export const router = createBrowserRouter([
  {
    path: "/linkedin",
    Component: LinkedInCallback,
  },
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/account-frozen",
    element: <AccountFrozenPage />,
  },
  {
    path: "/todesktop",
    element: <RedirectToDesktop />,
  },
  {
    path: "/",
    element: <UnonymLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
      },
      {
        path: "forgotpassword",
        element: <ForgotPassworPage />,
      },
      {
        path: "reset-password",
        element: <SetNewPassworPage />,
      },
    ],
  },
  {
    path: "app/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "brandhub",
        element: <BrandHubPage />,
      },
      {
        path: "onboarding",
        element: <OnboardingContent />,
      },
      {
        path: "brandhub/new",
        element: <BrandHubCreateNewPageWithTab />,
      },
      {
        path: "inspirations",
        element: <ContentInspirationsPage />,
      },
      {
        path: "home",
        element: <SelectContentHome />,
      },
      {
        path: "homepage",
        element: <SelectedContentHome />,
      },
      {
        path: "loops",
        element: <LoopsPage />,
      },
      {
        path: "loopdetails/:contentId/:shared",
        element: <LoopDetails />,
      },
      {
        path: "loopdetails/:contentId",
        element: <LoopDetails />,
      },
      {
        path: "revisionedit/:revisionId",
        element: <RevisionEditor />,
      },
      {
        path: "imagecontentedit/:revisionId",
        element: <ImageContentEditorPage />,
      },
      {
        path: "marketing-planner",
        element: <MarketingPlanner />,
      },
      {
        path: "marketing-planner/new",
        element: <MarketingPlannerDetail />,
      },
      {
        path: "content-calendar",
        element: <ContentCalendar />,
      },
      {
        path: "notifications",
        element: <AllNotifications />,
      },
      {
        path: "text-to-speech",
        element: <TextToSpeechPage />,
      },
      {
        path: "plan",
        element: <PlanPage />,
      },
      {
        path: "settings",
        element: <AccountSettingsPage />,
      },
      {
        path: "background-remover",
        element: <BackgroundRemoverPage />,
      },
    ],
  },
]);
